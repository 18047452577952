import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import Loadable from 'react-loadable';

//import registerSW, {unregister as unregisterSW} from '@bemit/flood-admin/lib/ServiceWorker';
//import registerSW from '@bemit/flood-admin/lib/ServiceWorker';
import '@bemit/flood-admin/style/base.scss';
import {LoadingRaw} from "@bemit/flood-admin/component/Loading";

import {ReactComponent as Logo} from '@bemit/flood-admin/asset/logo_white.svg';

//import App from './App';

const App = Loadable({
    loader: () => import('./App'),
    loading: (props) => (
        <LoadingRaw {...props} name='App'>
            <div style={{textAlign: 'center'}}>
                <Logo style={{width: '64px', height: '64px',}}/>
                <p style={{color: '#a0a0a0'}}>Hydro\Admin</p>
            </div>
        </LoadingRaw>
    ),
});

ReactDOM.render(<App/>, document.querySelector('#root'));
//registerSW();
//unregisterSW();